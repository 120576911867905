.container {
  margin: 10px;
}


.gridContainerSingleRowLayoutSpaced {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  padding: 5px;
  /* margin: auto; */
}
