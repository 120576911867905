/* .legendHeaderSelected {
  padding: 4px;
  width: 50%;
  background: #ffffff;
  border-bottom: 1px solid #ccc;
} */

.legendHeader {
  padding: 2px 10px 2px 10px;
  background: #f1f1f1;
  border: 1px solid #ccc;
}

.legendContent {
  padding: 10px;
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: auto;
  max-width: 400px;
  border: 1px solid #ccc;
  background: white;
}

.oddBg {
  background-color: #ebeef3;
}


.evenBg {
  background-color: #ffffff;
}

.none {
  display: none
}

/* .legendContent.collapsed {
    max-height: 0;
    padding: 0;
    border: none;
  } */


.title{
  padding-top: 5px;
  text-align: center;
}

.minButton{
  min-width: 30px;
}

.gridContainerSingleRow3 {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: auto;
}