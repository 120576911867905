.YFcontainer{
    border: 1px solid black;
    display: flex;
    flex-direction: column ;
    flex: 1 1 auto;
    overflow-x: "auto";
}

.YFcontainerHoverSelected{
    background: rgb(104, 157, 236);
}

.YFcontainerHoverNotSelected{
    background: rgb(166, 166, 167);
}
.YFcontainerHover:hover{
    cursor: pointer;
    background: rgb(60, 233, 167);
}

.grid-itemTitle {
    color: rgb(122, 121, 121);
    text-align: center;
    font-size: 14px;
    font-size: 0.8em;
  }

.cellHeight{
    /* min-height:"220px" */
}