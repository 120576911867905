.startArrowDiv {
    width: 80px;
    height: 20px;
}

.endArrowDiv {
    width: 1px;
}

.gridContainer2Columns {
    display: inline-grid;
    grid-template-columns: auto auto;
    margin-left: 10px;
}


.title {
    margin-bottom: 10px;
}

.tabsContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 5px;
}

.tabsHeader {
    display: flex;
    height: 40px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    font-weight: bold;
    color: #666;
    justify-content: center;
    align-items: center;
}