.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto ;
    max-width: 900px;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
    margin: 10px;
    max-width: 100px;
}

