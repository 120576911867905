/* Start of legend and eight letters */

.containerOfLegendLetter {
  position: relative;
  width: 100%;
}

.legend {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

/* .legend2 {
    position: absolute;
    top: 0;
    right: 100px;
    background: white;
    z-index: 3;
    border-bottom: 1px solid #ccc;
  } */

.content {
  overflow-x: auto;
  /* width: 700px; */
  /* Example height */
}

/* End of legend and eight letters */


.letterGridBorder {
  border: 1px solid rgba(0, 0, 0, 0.0);
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.center {
  margin: auto;
}



.gridContainer4Columns {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 0px;
  margin-bottom: 5px;
}

.gridContainer1Columns {
  display: inline-grid;
  grid-template-columns: auto;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 5px;

}

.gridContainerSingleRow {
  padding-top: 4px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(87px, 87px));
  margin: auto;
}

/* //hardcode for mobile */
@media (max-width: 1024px) {
  .gridContainerSingleRow {
    padding-top: 4px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48px, 48px));
    margin: auto;
  }
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* padding: 20px!; */
  /* font-size: 30px!; */
  text-align: center;

}

.grid-itemTitle {
  color: rgb(122, 121, 121);
  text-align: center;
  font-size: 14px;
  font-size: 0.8em;
}


.grid-itemClear {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  text-align: center;
  margin-left: 8px;
  font-size: 30px !;
  /* width: 110px; */
}


.table {
  display: table;
}

.row {
  display: table-row;
}

.cell {
  margin: 0px;
  padding-top: 0px;
  display: table-cell;
  /* padding: 1px;
    border: 1px solid #000;
    font-size: small; */
}


.cellDateInput {
  margin: 0px;
  padding-top: 5px;
  display: table-cell;
  padding-right: 8px;

}