.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0px 2px 8px 0px;
}

.comboBox {
  width: 100%;
  text-align: center;
}

.grid-cell {
  text-align: center;
}

.inputDatetime {
  width: 80%;
  /* default width for larger screens */
}

.genderPadding{
  padding-left: 10px;
}

.queryButton{
  text-align: center;
}

.text{
  text-align: left;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupOverlay.show {
  display: flex;
}

.popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.popupTitle {
  margin-bottom: 15px;
  text-align: center;
}

.dateTimeOption {
  padding: 8px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: #e2e4ff;

}

.dateTimeOption:hover {
  border: 1px solid #9982ff;

}

.dateTimeOption input[type="radio"] {
  cursor: pointer;
}

.dateTimeOption label {
  cursor: pointer;
  flex: 1;
}

/* //hardcode for mobile */
@media (max-width: 1024px) {
  .inputDatetime {
    width: 45vw;
    max-width: 200px;
    /* adjust as needed for mobile screens */
  }
}

.scrollableList {
  max-height: 200px;
  overflow-y: auto;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  margin: 10px 0;
  padding: 8px;
  background-color: white;
}