.App {
  text-align: left;
}

/* //hardcode for mobile */
/* Media query for mobile devices */
@media (max-width: 1024px) {
  .App {
    max-width: 648px; /* Use full width on mobile */
  }
}

.iFrame {
  width: 600px;
  height: 700px;
}

.detailTitle{
  background-color: rgb(229, 229, 231);
  text-decoration: underline;
  color: rgb(0, 0, 0);
  padding: 4px;
  border: 1px solid rgb(0, 0, 0);
}

.iFrameDetails {
  position:absolute;
  right: 0px;
  top:0px;
}
