.tabs-container {
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 500px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.tabs-header {
  display: flex;
  height: 40px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
}

.tabs-header button {
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: inherit;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.tabs-header button.active-tab {
  background-color: #fff;
  color: #000;
  border-bottom: 3px solid #0077ff;
}

.tab-content {
  flex-grow: 1;
  padding: 10px;
  background-color: #fff;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.solarTime {
  text-align: "right";
}

.grid-cell{
  padding: 1px;
  font-size: 0.8em;
}

.input-datetime {
  width: 80%; /* default width for larger screens */
}

/* hardcode for mobile */
/* Media query for screens smaller than 600px */
@media (max-width: 1024px) {
  .input-datetime {
    width: 45vw;
    max-width: 200px;
  }
}