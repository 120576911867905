.Gold{
    background-color: rgba(255, 251, 0);
}

.Water{
    background-color: rgb(127, 159, 247);
}

.Wood{
    background-color: rgb(142, 255, 127);
}

.Fire{
    background-color: rgb(243, 126, 151);
}

.Earth{
    background-color: rgb(179, 122, 89);
}