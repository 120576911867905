.horosopeTextArea {
  width: 100%;
  overflow: auto;
  /* height: 8vh;  */
  max-height: 500px;
}


/* index.module.css */
.tableContainer {
  margin: 10px auto;
  /* max-width: 800px; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  box-shadow: 0 0px 0px #ffffff1a;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHead {
  background-color: #f5f5f5;
}

.tableHeadCell {
  font-weight: bold;
  /* padding: 12px; */
  border-bottom: 2px solid #ddd;
}

.tableBodyCell {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.tableRow:hover {
  background-color: #eceaf8;
}

/* index.module.css */
.firstTableCell {
  min-width: 60px; 
  padding: 2px;
}