.YF10container{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.YF1container{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    overflow-x: auto;
}
