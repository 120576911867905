.navbar {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.logo {
  display: flex;
  align-items: center;
}

.logoWidth {
  width: 170px;
  height: auto;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  color: #333;
}