.container {
  margin: 10px;
}



.flexContainer {
  display: flex;
  flex-direction: row;
}



.gridContainerSingleRowLayoutSpaced {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  padding: 5px;
  /* margin: auto; */
}


.fullScreenContainer {
  position: absolute;
  top: 50vh;
  /* 1/3 of the screen height */
  left: 50vw;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* 
    The value -50% will move the element left by 50% of its own width, 
    and -33.33% up by 33.33% of its own height. */
}

/* //hardcode for mobile */
@media screen and (max-width: 1024px) {
  .fullScreenContainer {
    position: absolute;
    top: 50vh;
    /* 1/3 of the screen height */
    left: 50vw;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* 
        The value -50% will move the element left by 50% of its own width, 
        and -33.33% up by 33.33% of its own height. */
    width: 90vw;
    /* to force content make use of horizontal space */

  }
}


.logoWidth {
  width: 120vw;
  max-width: 168px;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
  width:100%
}

.logo {
  display: flex;
  align-items: center;

}

.title {
  margin-left: 10px;
  font-size: large;
}