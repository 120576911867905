.table {
    display: table;
  }
  
  .row {
    display: table-row;
  }
  
  .cell {
    display: table-cell;
    border: 1px solid #000;
    font-size:medium;
    padding: 1px;
    text-align: center;
  }
  
  .header .cell {
    padding: 1px;

    background-color: rgb(212, 211, 211);
  }