.letterGridBorder {
    border: 1px solid rgba(82, 81, 81, 0.8);
}

.letterGridEarth {
    display: grid;
    grid-template-columns: auto auto;
    width: 85px;
    height: 110px;
    word-wrap: break-word;
}

.letterGridSky {
    display: grid;
    grid-template-columns: auto auto;
    width: 85px;
    height: 110px;
    word-wrap: break-word;
}


.letterGrid>div {
    /* padding: 1px; */
    text-align: center;

}

.keyLetter {
    /* font-size:0.8em; */
    /* font-weight: bold; */
    text-align: center;
}

.extraInfo {
    font-size: smaller;
}

.chi {
    font-size: smaller;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;

}

/* //hardcode for mobile */
@media (max-width: 1024px) {
    .letterGridBorder {
        border: 0.5px solid rgba(0, 0, 0, 0.8);
    }

    .letterGridEarth {
        display: grid;
        grid-template-columns: 40% 60%;
        width: 48px;
        height: 80px;
        font-size: 0.8em;
        word-wrap: break-word;
    }

    .letterGridSky {
        display: grid;
        grid-template-columns: 40% 60%;
        width: 48px;
        height: 20px;
        font-size: 0.8em;
        word-wrap: break-word;
    }

    .extraInfo {
        font-size: 0.7em;
        /* font-size: clamp(0.6em, 1vw, 0.7em); */
        /* font size will be 1% of viewport width, but never smaller than 0.6em or larger than 0.8em */
    }

    .chi {
        font-size: 0.8em;
        text-align: center;
        cursor: pointer;
        border: 1px solid transparent;
    }
}


.chiSelected {
    border: 1px solid rgb(250, 0, 196);
    /* Border color when hovering */
    animation: blinkBorder 1s infinite alternate;
    /* Blink animation */
}

.chi:hover {
    border: 1px solid rgb(250, 0, 196);
    /* Border color when hovering */
    animation: blinkBorder 1s infinite alternate;
    /* Blink animation */
}

@keyframes blinkBorder {
    0% {
        border-color: rgb(66, 5, 8);
    }

    100% {
        border-color: rgb(250, 0, 196);
    }
}

.Gold {
    background-color: rgba(255, 251, 0);
}

.Water {
    background-color: rgb(127, 159, 247);
}

.Wood {
    background-color: rgb(142, 255, 127);
}

.Fire {
    background-color: rgb(243, 126, 151);
}

.Earth {
    background-color: rgb(179, 122, 89);
}