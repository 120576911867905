.horosopeTextArea {
  width: 100%;
  overflow: auto;
  height: 8vh; 
  max-height: 500px;
}


.tableContainer {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 800px;
  margin: 20px;

}

.tableRow {
  display: flex;
  margin-bottom: 3px;
}

.tableCell2 {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 10px;
  background-color: rgb(236, 234, 234);
}

.tableCell {
  flex: 1;
  padding: 10px;
  background-color: rgb(236, 234, 234);
}

.comboBox {
  width: 100%;
  padding: 5px;
}

.gridContainerSingleRowLayoutSpaced {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  padding: 5px;
  /* margin: auto; */
}